import { Time } from "~/types/general";

export const useTimeSet = (time: Time): string => {
  if (!time) return;

  const { hours, minutes, seconds } = time;

  return `${+hours < 10 ? `0${hours}` : hours}:${
    +minutes < 10 ? `0${minutes}` : minutes
  }:${seconds === 0 ? "00" : seconds}`;
};
